import React from "react";
import photo from "../../images/denys.jpg";
import classes from "./main.module.css";

const Main = () => {
  return (
    <main>
      <div className={classes.main__wrapper}>
        <div className={classes.left__block}>
          <div className={classes.left__block__upper__block}>
            <div className={classes.emojis__block}>
              <span>😜</span>
              <span>💻</span>
              <span>🇺🇦</span>
              <span>🌞</span>
            </div>
            <div className={classes.introduction__block}>
              <p>
                Hi, I'm Denys, a Senior iOS Developer from Kharkiv, Ukraine. Currently, I live in Rotterdam, the
                Netherlands. I'm a fan of good Netflix shows and high-quality code. I adore Asian food and can eat a lot
                of it. Two portions of ramen are my snack. (It's funny, right, cause in the photo I'm actually holding
                pizza).
              </p>
              <p>
                Currently, I work at a Dutch start-up for easy investing called{" "}
                <a className={classes.highlighted__link} target="_blank" rel="noreferrer" href="http://www.peaks.com/">
                  Peaks
                </a>{" "}
                as a Senior iOS developer in a friendly team.
              </p>
            </div>
          </div>
          <div className={classes.left__block__bottom__block}>
            <div className={classes.emojis__block}>
              <span>&#128541;</span>
              <span>&#9995;</span>
              <span>&#128221;</span>
              <span>&#128222;</span>
            </div>
            <div className={classes.contacts__block}>
              <p>Reach out to say hi, invite to an awesome project or send ramen recipe:</p>
            </div>
            <div className={classes.links}>
              <p>
                <a href="https://github.com/denyscodes" target="_blank" rel="noreferrer">
                  <span>&#127774;</span> GitHub
                </a>
              </p>
              <p>
                <a href="https://www.upwork.com/fl/denyscodes" target="_blank" rel="noreferrer">
                  {" "}
                  <span>&#128420;</span> Upwork
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className={classes.right__block}>
          <div className={classes.photo__wrapper}>
            <img src={photo} alt="Denys' eating pizza on restaurant's veranda" />
          </div>
        </div>
      </div>
    </main>
  );
};
export default Main;
