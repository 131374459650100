import React from "react";
import classes from "./projects.module.css";
import ProjectCard from "../../components/projectCard";
import spartacus from "../../images/spartacus.webp";
import picker from "../../images/picker.svg";
import tripwolf from "../../images/tripwolf.webp";
import riisu from "../../images/riisu.png";
import kamino from "../../images/kamino.jpeg";
import feedmenow from "../../images/feedmenow.jpeg";
import peaks from "../../images/peaks.png";

const projects = [
  {
    title: "Tripwolf",
    image: tripwolf,
    shortIntro: "Travel Guide",
  },
  {
    title: "Riisu",
    image: riisu,
    shortIntro: "Tech innovations",
  },
  {
    title: "Spartacus",
    image: spartacus,
    shortIntro: "Your internet privacy",
  },
  {
    title: "Picker",
    image: picker,
    shortIntro: "Pick your favorite products and share with friends",
  },
  {
    title: "Kamino",
    image: kamino,
    shortIntro: "Digital identity in one app",
  },
  {
    title: "FeedmeeNOW",
    image: feedmenow,
    shortIntro: "Order fresh grocery from major shops in one app",
  },
  {
    title: "Peaks",
    image: peaks,
    shortIntro: "Peaks makes investing effortless",
  },
];

const Projects = () => {
  return (
    <main>
      <div className={classes.projects__wrapper}>
        {projects.map(project => (
          <ProjectCard
            key={project.title}
            title={project.title}
            image={project.image}
            shortIntro={project.shortIntro}
            fullText={project.fullText}
          />
        ))}
      </div>
    </main>
  );
};

export default Projects;
